<template>
    <div > 
        <div style="background:white;line-height:30px;">
            <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
            <el-link   @click="addRec" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;">新增 </span></el-link>
    
            <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
            <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                <span v-for="item in tabCol" :key='item.key'>
                    <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                </span>
            </el-select>
            <el-input :placeholder="input_hold" v-model="textParam" @keyup.enter.native="stfgChange" size="small" style="width:320px;margin-left:10px;height:30px;margin-top:3px;">
                <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                    <span v-for="item in operArr" :key='item.IDSEQ'>
                        <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                    </span>
                </el-select>
                <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
            </el-input>
        </div> 
        <div>
        <el-table  :data="seoData" 
            border                            
            size="small"
            :height="tabHigh" 
            :row-key="getRowKey"
            ref="refdtl"
            highlight-current-row > 
            <span v-for="(item,index ) in tabCol" :key="index" > 
                <el-table-column   v-if="item.thide=='N'"
                    :prop="item.key"
                    :fixed="item.key==='RN'"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column>    
            </span> 
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="140">
                <template slot-scope="scope">
                    <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="certEdit(scope.row,scope.$index )">编辑</el-button>
                    <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="certDel(scope.row,scope.$index )">删除</el-button>  
                </template>
            </el-table-column>  
        </el-table>
        </div>
        <!--  SEO SET -->
        <el-dialog  class="pub_dialog" v-dialogDrag :visible.sync="addWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="3vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <Form :model="certRow" :label-width="120"  label-position="right" inline :rules="ruleValidate">  
                    <span v-for="(item,index) in tabCol" :key="index" >
                        <FormItem  :label="item.title"  :class="item.edcss=='textarea'?'item-width2':'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                            <el-input-number   v-if="item.edcss==='number' "  v-model="certRow[item.key]" style="width:100%"></el-input-number>
                            <el-input  type="textarea" v-else-if="item.edcss=='textarea'"  :rows="4" v-model="certRow[item.key]"></el-input>
                            <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false"  v-model="certRow[item.key]"></el-input>
                        </FormItem>
                    </span>
                </Form>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="addWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="saveRec"  style="margin-right:10px;">保存</el-button>
                </span>
            </div>
        </el-dialog>
 
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
 
 
    </div>  
</template>
<script>
 
 
import { getBaseData,getTabColOrData } from '../../api/user'
import {  paramData } from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
 
//局部注册
export default {
    name:"seo_optimize",
    data () {
        return {
            stfgBool:true,
            ruleValidate:{},
            operArr:[],  
            selparam:'CNAME',
            textParam:'',
            input_hold:'请输入查询值',
            oper:'like',
 
            tabCol:[],
            seoData:[],
            addWin:false,
            addFlag:'create',
 
            certRow:{},
 
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            notNull:[],
 
 
 
        }
    },
    mounted () {   
        this.getSeoList()
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','45816','fty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });  
    },
    computed: {
 
        tabHigh: function() {
            return (window.innerHeight - 130) + 'px';
        }, 
    },
    components: {

    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
  
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
 
    },
    methods: { 
        // 保存
        saveRec(){
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.certRow[item.FLDNO] ){
                    this.stfgBool =false 
                    this.promptWin=true
                    this.prompt=item.FLDNM+'不能为空'
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            //发起保存请求   
            if (this.stfgBool) {   
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.certRow), p_table:'seoset',frmid:'45816' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }else{
                        this.getSeoList()
                        this.addWin=false
                    }
                })    
            }   
        },
 
        //add record
        addRec(){
            this.certRow={IDSEQ:'' }
            this.addWin=true
            this.addFlag='create'
        },
 
        getRowKey(row){
            return row.IDSEQ +'-'+row.LSTSEQ
        },
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','45816','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.tabCol.push({  
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        edcss: res.data[k].EDCSS,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        width: res.data[k].width,
                        sortable: res.data[k].sortable?true:false,
                        disabled: res.data[k].DISABLED,
                    })
                }
            });
 
        },
        certDel(row,index){
            this.$confirm('你确定要删除此记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                console.log('vlaue:'+row['IDSEQ'])
                //删除
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,  p_table:'SEOSET'},
                }).then(res=>{
                    this.seoData.splice(index,1 )
                })
           }) 
        },
        certEdit(row,index){
            this.addFlag='edit'
            this.certRow=JSON.parse(JSON.stringify(row))
            this.addWin=true
        },
 
        // 查询明细 
        getSeoList(cond){
            this.seoData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_SEOSET','','','','','','',cond ).then((res) => { 
                this.seoData =res.data 
            })
            setTimeout(() => {
                this.$refs.refdtl.doLayout();
            }, 500);
        },
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%'+this.textParam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam+this.oper+'\''+this.textParam+'\''
                }
            }
            this.getSeoList(cond_)
        },
 
    }
}
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .item-width2  {
        width: 97%;
        color:#409EFF;
    }
 
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
 
</style>
 